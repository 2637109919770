.kapskart {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 700px;
}
.kart {
  position: relative;
  background: linear-gradient(
    15deg,
    rgba(255, 68, 0, 1) 0%,
    rgba(255, 0, 86, 1) 100%
  );
  margin: 0.2rem 0;
  width: 100%;
  border-radius: 1rem;
  display: flex;
  font-family: "JetBrains Mono", monospace;
}

.kartyazi {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
}

.links {
  display: block;
}

.kartbutton,
.kartbutton1 {
  width: 20%;
  height: 50%;
  background-color: #169453;
  position: absolute;
  border-radius: 0 0.7rem 0 0;
  right: 0;
  top: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 3rem;
  color: #e6e6fa;
  user-select: none;
}

.kartbutton1 {
  background-color: #494e53;
  top: 50%;
  border-radius: 0 0 0.7rem 0;
}

.kartbutton {
  background-color: #169453;
  top: 0%;
  border-radius: 0 0.7rem 0 0;
}

.kartbutton:hover,
.kartbutton1:hover {
  background-color: #660000;
  cursor: pointer;
}

.bolge {
  color: #e6e6fa;
  text-align: left;
  padding: 7px 0 7px 12px;
  font-size: 2rem;
  font-weight: bold;
  font-family: "JetBrains Mono", monospace;
}

.aciklama {
  margin: 0.5rem 0 0 0;
  color: #f5e2b8;
  font-weight: bold;
  font-size: 1.1rem;
}

.ad {
  font-size: 1.2rem;
}

.adres {
  padding-bottom: 0.35rem;
  font-weight: bold;
}

.tel > a {
  color: #e6e6fa;
  text-decoration: none;
  cursor: pointer;
  background-color: #8f0f3a;
  padding: 0.35rem;
  border-radius: 5px;
}

.tel {
  padding: 1rem 0;
  font-weight: bold;
}
