.App {
  text-align: center;
  width: 95vw;
  align-items: center;
  justify-content: center;
  display: flex;
}
.baslik1,
.tarihsel {
  font-size: 1rem;
  color: aliceblue;
  font-family: "JetBrains Mono", monospace;
}

.baslikim {
  text-transform: uppercase;
  font-size: 2rem;
  color: aliceblue;
  padding: 15px 0 0 0;
  font-family: "JetBrains Mono", monospace;
}

.arama {
  width: 85%;
  height: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  outline: none;
  font-family: "JetBrains Mono", monospace;
}

input::placeholder {
  opacity: 0.5;
  padding-left: 1rem;
  font-size: 1rem;
}

.yukleniyor {
  font-size: 1.5rem;
  color: aliceblue;
  font-family: "JetBrains Mono", monospace;
}
