@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@600&display=swap");
body {
  background-color: #1e162e !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
